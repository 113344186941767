<template>
  <div>
    <PageLoader v-bind:storage="appLoading" />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showSnackBar" color="#68D389" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left align-self-center>{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap>
      <v-flex xs12 align-self-center>
        <v-card elevation-0 flat>
          <v-layout wrap justify-center>
            <v-flex xs12 sm9 text-center>
              <v-layout wrap py-2>
                <v-flex xs12 text-left text-uppercase pl-3>
                  <span style="color: #000000; font-family: poppinsbold"
                    >Shopping</span
                  >
                </v-flex>
                <v-flex text-left xs12 pl-3>
                  <span style="color: #9e9e9e; font-family: poppinsregular">{{
                    product.name
                  }}</span>
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs12>
              <v-divider></v-divider>
            </v-flex>
          </v-layout>
        </v-card>
        <v-layout wrap justify-center justify-md-end v-if="product">
          <v-flex xs12 lg12 xl11>
            <v-layout wrap justify-center>
              <v-flex xs12 sm12 md6 lg5 xl5 pt-2 pa-2>
                <v-layout wrap justify-start>
                  <v-flex xs12 sm4 md4 lg5 xl4 v-if="product.images">
                    <v-card tile flat v-if="product.images.length > 0">
                      <viewer
                        :images="product.images"
                        :key="product.images.length"
                      >
                        <v-layout wrap justify-center pb-2>
                          <v-flex pa-2>
                            <img
                              :src="mediaURL + product.images[0]"
                              width="100%"
                            />
                          </v-flex>
                        </v-layout>
                      </viewer>
                    </v-card>
                  </v-flex>
                  <v-flex xs12 sm8 md8 lg7 xl8 pt-2 px-2 v-if="product.units">
                    <v-flex xs12>
                      <span style="font-family: poppinsbold;color:#000"
                        >{{ product.name }} </span
                      ><br v-if="this.$route.query.sizename" />
                      <span
                        v-if="this.$route.query.sizename"
                        style="
                          font-family: poppinsregular;
                          color: #53a874;
                          font-size: 14px;
                        "
                        >{{ this.$route.query.sizename }} </span
                      ><span
                        v-if="this.$route.query.subsize"
                        style="
                          font-family: poppinsregular;
                          color: #53a874;
                          font-size: 14px;
                        "
                        >- {{ this.$route.query.subsize }}
                      </span>
                      <br />
                      <span
                        style="
                          font-family: poppinsregular;
                          color: #3c3c3c;
                          font-size: 14px;
                        "
                        >WTI</span
                      >
                    </v-flex>
                    <v-layout justify-start wrap>
                      <v-flex
                        text-left
                        align-self-center
                        xs6
                        sm3
                        md3
                        lg4
                        xl3
                        v-if="!newprice"
                      >
                        <span
                          style="font-family: poppinssemibold; font-size: 20px;color:#000"
                          >Rs. {{ product.price }}</span
                        >
                      </v-flex>

                      <v-flex
                        text-left
                        align-self-center
                        xs6
                        sm3
                        md3
                        lg4
                        xl3
                        v-if="newprice"
                      >
                        <span
                          style="font-family: poppinssemibold; font-size: 20px"
                          >Rs. {{ newprice }}</span
                        >
                      </v-flex>
                      <v-flex
                        text-left
                        align-self-center
                        xs6
                        sm4
                        md8
                        lg8
                        xl8
                        pl-1
                        style="padding-top: 5px !important"
                      >
                        <span
                          style="
                            color: #616161;
                            font-family: poppinsregular;
                            font-size: 12px;
                          "
                          >Inclusive of all taxes</span
                        >
                      </v-flex>
                    </v-layout>
                    <v-flex xs12 text-left>
                      <span
                        v-if="sizeType.stock > 0"
                        class=""
                        style="
                          font-family: poppinsregular;
                          font-size: 14px;
                          color: #53a874;
                        "
                      >
                        In Stock
                      </span>
                      <span
                        v-else
                        class=""
                        style="
                          font-family: poppinsregular;
                          font-size: 14px;
                          color: red;
                        "
                      >
                        Out of Stock
                      </span>
                    </v-flex>

                    <!-- <v-flex xs12 text-left v-if="product.units.length > 0 && product.units[0].size.length <= 0">
                      <span
                        v-if="sizeType.stock > 0"
                        class=""
                        style="
                          font-family: poppinsregular;
                          font-size: 14px;
                          color: #53a874;
                        "
                      >
                        In Stock (no size)
                      </span>
                      <span
                        v-else
                        class=""
                        style="
                          font-family: poppinsregular;
                          font-size: 14px;
                          color: red;
                        "
                      >
                        Out of Stock (no size)
                      </span>
                    </v-flex>

                    <v-flex xs12 text-left v-if="product.units.length < 0">
                      <span
                        v-if="product.totalQuantity > 0"
                        class=""
                        style="
                          font-family: poppinsregular;
                          font-size: 14px;
                          color: #53a874;
                        "
                      >
                        In Stock (no units)
                      </span>
                      <span
                        v-else
                        class=""
                        style="
                          font-family: poppinsregular;
                          font-size: 14px;
                          color: red;
                        "
                      >
                        Out of Stock (no units)
                      </span>
                    </v-flex> -->

                    <!-- stock end  -->

                    <!-- <v-flex xs12 text-left>
                      <span
                        style="
                          color: #2dd986;
                          font-size: 14px;
                          font-family: poppinsregularItalicAz;
                        "
                        >You Save:₹0</span
                      >
                    </v-flex> -->
                    <!-- <v-flex xs12 text-left>
                      <span
                        style="
                          color: #000000;
                          font-family: poppinsregular;
                          font-size: 16px;
                        "
                        >Shipping Charge: ₹{{
                          finalCalculation.deliveryCharge
                        }}</span
                      >
                    </v-flex> -->
                    <v-layout justify-start wrap>
                      <!-- <v-flex xs12 text-left py-2>
                    <span
                      v-for="(item, i) in productType1.size"
                      :key="i"
                      class="px-1"
                    >
                      <v-btn
                        small
                        rounded
                        depressed
                        outlined
                        @click="changePrice(item)"
                        :ripple="false"
                        :color="
                          sizeType.sizename == item.sizename ? '#FF0000' : '#919191'
                        "
                        class="sizebutton px-2"
                      >
                        {{ item.sizename }}
                      </v-btn>
                    </span>
                  </v-flex> -->
                      <v-flex xs12 text-left py-2>
                        <v-layout wrap>
                          <v-flex py-xl-6 xs6 xl3  lg6 text-left pt-3>
                            <span
                              style="
                                font-family: poppinssemibold;
                                font-size: 16px;
                                color: #000000;
                              "
                              >Choose quantity</span
                            >
                          </v-flex>
                          <v-flex
                            py-2
                            py-lg-0
                            xs6
                            sm5
                            md5
                            lg5
                            xl4
                            px-sm-1
                            px-lg-0
                            px-xl-10
                            align-self-center
                            text-center
                          >


                           <v-card flat>
                            <v-layout wrap justify-center>
                              <v-flex xs2 sm2 md2 align-self-center text-left>
                               
                                <v-icon
                                  @click="quantity--"
                                  :disabled="quantity <= 1"
                                >
                                  mdi-minus
                                </v-icon>
                              </v-flex>
                              <v-flex
                                xs6
                                sm6
                                md8
                                align-self-center
                                pa-1
                                text-center
                              >
                                <v-btn outlined tile>
                                  <span class="poppinsregular">
                                    {{  quantity }}
                                  </span>
                                </v-btn>
                              </v-flex>
                              <v-flex xs2 sm2 md2 align-self-center text-right>
                                  <v-icon
                                  @click="quantity++"
                                  :disabled="quantity == sizeType.stock"
                                >
                                  mdi-plus
                                </v-icon>
                              </v-flex>
                            </v-layout>
                          </v-card>
                            <!-- <v-text-field
                              id="slotInput"
                              class="boxed"
                              placeholder="1"
                              outlined
                              hide-details
                              dense
                              color="#CCCCCC"
                              background-color="#FFFFFF"
                              append-outer-icon="mdi-plus"
                              readonly
                              v-model="quantity"
                            >
                              <template v-slot:prepend>
                                <v-icon
                                  @click="quantity--"
                                  :disabled="quantity <= 1"
                                >
                                  mdi-minus
                                </v-icon>
                              </template>
                              <template v-slot:append-outer>
                                <v-icon
                                  @click="quantity++"
                                  :disabled="quantity == sizeType.stock"
                                >
                                  mdi-plus
                                </v-icon>
                              </template>
                            </v-text-field> -->
                          </v-flex>
                        </v-layout>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
                <v-layout wrap justify-center pt-2>
                  <v-flex xs11>
                    <v-divider></v-divider>
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex xs12 sm12 md4 lg4 xl4 px-2 py-4 v-if="newprice">
                <!-- new {{ newprice }} new {{ quantity }} -->
                <PriceDetails
                  :qty="quantity"
                  :newprice="newprice"
                  :types="$route.query.unitid"
                  :productId="$route.query.id"
                  :unitId="$route.query.unitid"
                  :sizeId="$route.query.sizeid"
                  :finalCalculation="finalCalculation"
                />
              </v-flex>
              <v-flex v-else xs12 sm12 md4 pt-4 lg4 xl4 px-2>
                <PriceDetails
                  :qty="quantity"
                  :newprice="product.price"
                  :types="$route.query.unitid"
                  :productId="$route.query.id"
                  :unitId="$route.query.unitid"
                  :sizeId="$route.query.sizeid"
                  :finalCalculation="finalCalculation"
                  @stepper="winStepper"
                />
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
import PriceDetails from "./priceDetails";
export default {
  components: {
    PriceDetails,
  },
  props: ["id", "types", "qty"],
  data() {
    return {
      appLoading: false,
      ServerError: false,
      showSnackBar: false,
      timeout: 5000,
      value: null,
      product: [],
      productType: [],
      productType1: {},
      sizeType: {},
      size: ["8", "9", "10", "11"],
      items: ["1KG", "2KG", "3KG", "4KG", "5KG"],
      msg: null,
      newprice: 0,
      quantity: 1,
      finalCalculation: {},
      deliveryAddressId: null,
    };
  },
  watch: {
    types() {
      this.getData();
    },
    quantity() {
      if (this.$route.query.sizeid) {
        this.newprice = this.quantity * this.sizeType.price;
      } else if (this.$route.query.unitid && !this.$route.query.sizeid)
        this.newprice = this.quantity * this.sizeType.price;
      else if (!this.$route.query.unitid && !this.$route.query.sizeid)
        this.newprice = this.quantity * this.sizeType.unitPrice;
      this.getPrice();
    },
    deliveryAddressId() {
      this.getPrice();
    },
  },
  beforeMount() {
    this.getData();
    // if(this.quantity)
    // this.getPrice();

    this.value = this.$route.query.value;
    // if(this.product.totalQuantity>=this.qty && this.productType.stock>=this.qty) {
    this.quantity = this.$route.query.quantity;
    // }
  },
  methods: {
    winStepper(item) {
      if (item.deliveryAddressId)
        this.deliveryAddressId = item.deliveryAddressId;
      if (item.getPrice) this.getPrice();
    },
    // alertme() {
    //   alert(
    //     this.quantity +
    //       "\nqty" +
    //       this.qty +
    //       "\ntypes" +
    //       this.types +
    //       "\ntypechange" +
    //       this.productType.type +
    //       "\ntypechangeid" +
    //       this.productType._id +
    //       "\nproprice" +
    //       this.product.price +
    //       "\nnewprice" +
    //       this.newprice
    //   );
    // },
    changeSize(item) {
      this.productType1 = item;
      this.changePrice(item.size[0]);
    },
    changePrice(item) {
      this.sizeType = item;
      this.quantity = 1;
      this.newprice = item.price;
      // alert( this.newprice)
    },
    getPrice() {
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/product/getprice",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          id: this.$route.query.id,
          unitid: this.$route.query.unitid,
          sizeid: this.$route.query.sizeid,
          quantity: this.quantity,
          deliveraddressid: this.deliveryAddressId,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.finalCalculation = response.data.data;
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    getData() {
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/product/get",
        data: {
          id: this.$route.query.id,
          unitid: this.$route.query.unitid,
          sizeid: this.$route.query.sizeid,
          quantity: this.$route.query.quantity,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.product = response.data.data;
            if (this.product.units.length > 0) {
              for (let i = 0; i < this.product.units.length; i++) {
                if (this.$route.query.unitid == this.product.units[i]._id) {
                  this.productType1 = this.product.units[i];

                  if (this.productType1.size.length > 0) {
                    for (let j = 0; j < this.productType1.size.length; j++) {
                      if (
                        this.$route.query.sizeid ==
                        this.productType1.size[j]._id
                      ) {
                        this.sizeType = this.productType1.size[j];
                      }
                    }
                  }
                  //new updates
                  else {
                    this.sizeType = this.productType1;
                    console.log("level1", this.sizeType.price);

                    this.sizeType._id = null;
                  }
                }
              }
            }
            //new updates
            else {
              this.sizeType = this.product;
              this.sizeType.stock = this.product.totalQuantity;
              console.log("level0", this.sizeType.price);

              this.sizeType._id = null;
            }

            // this.productType.stock = 0;
            if (this.$route.query.quantity == 0) {
              this.quantity = 1;
            }
          } else {
            this.msg = response.data.msg;
            this.myGeeks(this.msg)
            // this.$router.push(
            //   "/productDetails/" + this.id + "/" + this.types + "/" + 1
            // );
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    getData1() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/product/get",
        params: {
          id: this.$route.query.id,
          unitid: this.$route.query.unitid,
          sizeid: this.$route.query.sizeid,
          quantity: this.quantity,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.product = response.data.data;
            if (this.product.units.length > 0) {
              for (let i = 0; i < this.product.units.length; i++) {
                if (this.$route.query.unitid == this.product.units[i]._id) {
                  this.productType1 = this.product.units[i];

                  if (this.productType1.size.length > 0) {
                    for (let j = 0; j < this.productType1.size.length; j++) {
                      if (
                        this.$route.query.sizeid ==
                        this.productType1.size[j]._id
                      ) {
                        this.sizeType = this.productType1.size[j];
                      }
                    }
                  }
                  //new updates
                  else {
                    this.sizeType = this.productType1;
                    console.log("level1", this.sizeType.price);

                    this.sizeType._id = null;
                  }
                }
              }
            }
            //new updates
            else {
              this.sizeType = this.product;
              this.sizeType.stock = this.product.totalQuantity;
              console.log("level0", this.sizeType.price);

              this.sizeType._id = null;
            }

            // this.productType.stock = 0;
            if (this.$route.query.quantity == 0) {
              this.quantity = 1;
            }
          } else {
            this.msg = response.data.msg;
            // this.$router.push(
            //   "/productDetails/" + this.id + "/" + this.types + "/" + 1
            // );
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },

    myGeeks(strr) {
            var str = strr;
            var matches = str.match(/(\d+)/);
              
            if (matches) {
                // alert(matches[0])
                this.quantity=matches[0]
                this.getData1()
            }
        }
  },
};
</script>
<style>
.content {
  font-family: sofiaProSemiBold;
  font-size: 14px;
  color: #474747;
}
.shopping {
  font-family: sofiaProSemiBold;
  color: #474747;
  font-size: 14px;
}
.custom.v-text-field > .v-input__control > .v-input__slot:before {
  border-style: none;
}

#slotInput {
  text-align: center;
}
.boxed {
  text-align: center;
  padding-inline-start: 20px;
  border-radius: 0px;
}
</style>